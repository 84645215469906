<template>
  <div>
    <div v-if="!isPostVenta">
      <div v-if="polizasCliente.length > 0 && !sPostVenta">
        <div class="pt-5" v-for="(poliza, idx) in polizasCliente" :key="idx">
          <CommonExpansionPanel>
            <template v-slot:title-panel>
              Datos de Póliza #{{ poliza.id }}
            </template>
            <v-expansion-panel-content>
              <div>
                <EmisionDetalleCliente :polizaData="poliza" />
              </div>
            </v-expansion-panel-content>
          </CommonExpansionPanel>
        </div>
      </div>
      <div v-else>
        <v-col
          cols="12"
          md="12"
          style="align-items: center; text-align: center; padding: 5%;"
        >
          <span class="titulo-header">Sin resultados que mostrar</span>
        </v-col>
      </div>
    </div>
    <div v-if="isPostVenta">
      <EmisionesIframe :clienteInfo="clienteInfo" />
    </div>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import EmisionDetalleCliente from "./EmisionDetalleCliente.vue";
import EmisionesIframe from "./EmisionesIframe.vue";

export default {
  components: {
    CommonExpansionPanel,
    EmisionDetalleCliente,
    EmisionesIframe,
  },
  props: {
    polizasCliente: Array,
    canEdit: {
      type: Boolean,
      default: true,
    },
    clienteInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      switch_data: 1,
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
  },

  computed: {
    isPostVenta() {
      if (this.rol.includes("INBOUND")) return true;
      else return false;
    },
  },
};
</script>
